import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateResumeConfirmationDialog } from "../../redux/actions/dialog_action";
import { getUser } from "../../redux/actions/user_action";

const WithOnboarding = (WrappedComponent) => {
  return (props) => {
    const Router = useRouter();
    const [allowOnboarding, setAllowOnboarding] = useState(false);

    const dispatch = useDispatch();

    const onboardingRevampDate = "2022-12-20";

    const userCompletedOnboarding = (user) => {
      const createdAt = user.created_at || user.createdAt;

      // Set user as completed onboarding if the acc is created before 1st of DEC
      if (createdAt) {
        if (moment(createdAt).isBefore(onboardingRevampDate)) {
          return true;
        }
      }

      const onboardingSuccess =
        user.onboardingComplete &&
        user.resume !== "" &&
        user.uploadResumeAt !== null;

      return onboardingSuccess;
    };

    useEffect(() => {
      const user = getUser();

      if (user) {
        const completedOnboarding = userCompletedOnboarding(user);

        if (completedOnboarding) {
          // Done first step, no need to go through onboarding anymore
          if (Router.pathname === "/onboarding") {
            Router.replace("/");
          } else {
            setAllowOnboarding(true);
            dispatch(updateResumeConfirmationDialog(false));
          }
        } else {
          // Not yet done first step
          if (Router.pathname === "/onboarding") {
            setAllowOnboarding(true);
          } else {
            // Try to go to other page, redirect back to onboarding page
            setAllowOnboarding(true);
            dispatch(updateResumeConfirmationDialog(true));
          }
        }
      } else {
        // Not logged in, redirect back to user landing page
        if (Router.pathname === "/onboarding") {
          Router.replace("/");
        } else {
          setAllowOnboarding(true);
        }
      }
    }, []);

    if (allowOnboarding) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};

export default WithOnboarding;
