import Divider from "@mui/material/Divider";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  formCompanyQueryParam,
  formCompanySuggestionQueryParam,
} from "../../../functions/company";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  clearCompanyList,
  clearCompanySuggestion,
  clearDiscoverMoreCompanyList,
  getCompanySuggestions,
  getTopEightCompanies,
  updateCompanyFilter,
} from "../../../redux/actions/company_action";
import {
  clearJobList,
  clearJobSuggestion,
  clearSearchSuggestions,
  getJobs,
  updateJobAlertToggle,
  updateJobListFilter,
  updateJobListFilterLanding,
  updateJobSearchKeyword,
} from "../../../redux/actions/job_action";
import { updateShowMobileFilter } from "../../../redux/actions/navbar_action";
import { store } from "../../../redux/stores/store";
import * as types from "../../../redux/types/company_type";
import * as jobTypes from "../../../redux/types/job_type";
import Button from "../../shared/SharedButton/SharedButton";
import SharedMobileSuggestion from "../SharedMobileSuggestion/SharedMobileSuggestion";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  GridFilterContent,
  GridStyled,
  InputBaseStyled,
  NavbarSearchStyled,
} from "./styles";

let getCompanySuggestionTimer;
let isLoadingCompanySuggestion = false;

function SharedMobileFilterDialog(props) {
  const { page } = props;
  const router = useRouter();
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", windowOnResizeListener);

    return () => {
      window.removeEventListener("resize", windowOnResizeListener);
    };
  }, []);

  useEffect(() => {
    if (props.openSearchDialog) {
      props.clearJobSuggestion().then(() => {
        triggerSuggestionSearchOnLoad();
      });
    }
  }, [props.openSearchDialog]);

  const windowOnResizeListener = () => {
    if (window.innerWidth >= 480) {
      props.updateShowMobileFilter(false);
    }
  };

  const handleOpen = () => {
    setOpenFilter(true);
  };

  const handleClose = () => {
    setOpenFilter(false);
  };

  const handleCancel = () => {
    props.updateShowMobileFilter(false);
  };

  const triggerSuggestionSearchOnLoad = () => {
    if (
      props.page == "company-list-page" &&
      store.getState().companies.companyFilter?.keyword
    ) {
      triggerGetCompanySuggestions(true);
    }
  };

  const handleOnChangeSearch = (e) => {
    dispatch(
      updateJobSearchKeyword({ keyword: e?.currentTarget?.value ?? "" })
    );

    if (props.page == "company-list-page") {
      let companyFilter = JSON.parse(
        JSON.stringify(store.getState().companies.companyFilter)
      );
      companyFilter["keyword"] = e.currentTarget.value;

      props.updateCompanyFilter(companyFilter).then(() => {
        triggerGetCompanySuggestions(true);
      });
    } else {
      jobFilterSelected("keyword", e.currentTarget.value);
      setSearch(e.target.value);
      // Hide job alert toggle if no keyword searched
      if (e.target.value == "") {
        props.updateJobAlertToggle(false);
      }
    }
  };

  const jobFilterSelected = (key, value) => {
    if (props.page == "user-landing-page") {
      let currentLandingFilter = JSON.parse(
        JSON.stringify(store.getState().jobs.jobListFilterLanding)
      );

      currentLandingFilter[key] = [value];

      props.updateJobListFilterLanding(currentLandingFilter);
    } else {
      let jobFilter = JSON.parse(
        JSON.stringify(store.getState().jobs.jobListFilter)
      );

      jobFilter[key] = [value];

      props.updateJobListFilter(jobFilter);
    }
  };

  const triggerGetCompanySuggestions = (refresh) => {
    if (
      (isLoadingCompanySuggestion ||
        store.getState().companies.companySuggestionEnd) &&
      !refresh
    ) {
      return;
    }

    isLoadingCompanySuggestion = true;

    if (getCompanySuggestionTimer) {
      clearTimeout(getCompanySuggestionTimer);
    }

    getCompanySuggestionTimer = setTimeout(() => {
      if (refresh) {
        props.clearCompanySuggestion();
      }

      // Cancel previous job suggestion graphQL
      if (
        store.getState().axios.cancelTokens[types.FETCH_COMPANY_SUGGESTION_KEY]
      ) {
        store
          .getState()
          .axios.cancelTokens[types.FETCH_COMPANY_SUGGESTION_KEY].cancel(
            "company suggestion"
          );
      }

      let params = formCompanySuggestionQueryParam(
        store.getState().companies.companyFilter,
        refresh
      );

      params["page"] = refresh ? 1 : store.getState().companies.suggestionPage;

      props
        .getCompanySuggestions(params)
        .then((response) => {
          isLoadingCompanySuggestion = false;
        })
        .catch((error) => {
          isLoadingCompanySuggestion = false;
        });
    }, 900);
  };

  const onDiscoverButtonClicked = () => {
    scrollTo(0, 0);
    if (page === "company-list-page") {
      // Trigger reload companies
      onTriggerDiscoverCompany();
    } else if (router.pathname === "/") {
      store.getState().jobs.fromLandingPage = true;
      router.push("/jobs");
    } else if (router.pathname === "/jobs/[id]") {
      store.getState().navbar.fromNavbarSearch = true;
      store.getState().jobs.fromNavSearchbar = true;
      router.push("/jobs");
    } else if (router.pathname.includes("/jobs") || page === "job-list-page") {
      const cKeyword = search;

      if (cKeyword?.trim()?.length > 0) {
        const keyword = cKeyword.trim();
        sendTrackingEvent({
          event: "CE_search-job-nav-job-list",
          search_term: `${keyword}`,
        });
      }
      // Trigger reload jobs
      onTriggerDiscoverJob();
    }
    handleClose();
    props.updateShowMobileFilter(false); // Hide mobile filter
  };

  const onTriggerDiscoverCompany = () => {
    scrollTo(0, 0);
    if (store.getState().companies.isLoadingCompanies) return;

    store.getState().companies.isLoadingCompanies = true;

    // Cancel any existing call to graphQL
    if (store.getState().axios.cancelTokens[types.FETCHING_COMPANY_KEY]) {
      store
        .getState()
        .axios.cancelTokens[types.FETCHING_COMPANY_KEY].cancel(
          "company search navbar mobile"
        );
    }

    // Trigger custom event for company keyword search on company page mobile
    if (store.getState().companies.companyFilter?.keyword?.length > 0) {
      const keyword = store
        .getState()
        .companies.companyFilter.keyword.toLowerCase();
      sendTrackingEvent({
        event: "CE_search-company-nav",
        search_term: `${keyword}`,
      });

      dispatch(updateJobSearchKeyword({ keyword: keyword ?? "" }));
    }

    // Clear current company list and discover more company list
    props.clearDiscoverMoreCompanyList();
    props
      .clearCompanyList()
      .then((response) => {
        // Call graphQL
        let params = formCompanyQueryParam(
          store.getState().companies.companyFilter,
          true
        );

        props
          .getTopEightCompanies(params)
          .then((response) => {
            store.getState().companies.isLoadingCompanies = false;
          })
          .catch((error) => {
            store.getState().companies.isLoadingCompanies = false;
          });
      })
      .catch((error) => {
        store.getState().companies.isLoadingCompanies = false;
      });
  };

  const onTriggerDiscoverJob = (suggestion) => {
    scrollTo(0, 0);
    if (store.getState().jobs.isLoadingJobs) return;

    store.getState().jobs.isLoadingJobs = true;

    props.updateJobAlertToggle(true, true);

    // Cancel any existing call to graphQL
    if (store.getState().axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY]) {
      store
        .getState()
        .axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY].cancel(
          "job search navbar mobile"
        );
    }
    const keyword = suggestion ? suggestion : search;
    const query = router.query;

    dispatch(updateJobListFilter({ keyword: keyword })).then(() => {
      dispatch(updateJobSearchKeyword({ keyword: keyword }));
      const { filter, ...queryParams } = query;

      const filteredQuery =
        query.filter.includes("jobs") ||
        query.filter.some((item) => item.endsWith("-jobs"))
          ? query.filter.filter(
              (item) => !item.includes("jobs") && !item.endsWith("-jobs")
            )
          : query.filter;

      const cleanedQuery =
        filteredQuery &&
        filteredQuery.map((item) => {
          if (item.startsWith("jobs-in-")) {
            return item.replace("jobs-", "");
          }
          return item;
        });

      const keywordSlug =
        keyword.toLowerCase()?.replace(/\s+/g, "-") + "-jobs/";
      const slug = keywordSlug + cleanedQuery.join("/");

      if (page === "job-list-page") {
        router.push(
          {
            pathname: keyword ? slug : "/jobs",
            query: queryParams,
          },
          undefined,
          { shallow: true }
        );
      } else {
        router.push({
          pathname: keyword ? slug : "/jobs",
          query: queryParams,
        });
      }
    });
    dispatch(clearSearchSuggestions());
  };

  const onKeyDown = (event) => {
    // Prevent triggering of keyword search on pressing Enter when Ashley is switched on
    if (event.key === "Enter") {
      onDiscoverButtonClicked();
    }
  };

  const getFilter = (companyFilter, jobListFilter, jobListFilterLanding) => {
    if (props.page == "company-list-page") {
      return companyFilter;
    } else if (props.page == "user-landing-page") {
      return jobListFilterLanding;
    } else {
      return jobListFilter;
    }
  };

  return (
    <GridStyled>
      <DialogStyled
        fullScreen={true}
        open={props.openSearchDialog || props.openFilterDialog}
        aria-labelledby="filter-dialog"
      >
        {props.openSearchDialog && (
          <Fragment>
            <GridFilterContent id="mobile-searchbar">
              <NavbarSearchStyled
                component="div"
                elevation={0}
                page={props.page}
              >
                <InputBaseStyled
                  page={props.page}
                  onChange={handleOnChangeSearch}
                  defaultValue={
                    getFilter(
                      props.companyFilter,
                      props.jobListFilter,
                      props.jobListFilterLanding
                    )["keyword"]
                      ? getFilter(
                          props.companyFilter,
                          props.jobListFilter,
                          props.jobListFilterLanding
                        )["keyword"]
                      : ""
                  }
                  placeholder={
                    props.page == "company-list-page"
                      ? "Discover Companies"
                      : "Discover Job"
                  }
                  inputProps={{
                    "aria-label":
                      props.page == "company-list-page"
                        ? "discover company search"
                        : "discover job search",
                  }}
                  onKeyDown={onKeyDown}
                />
              </NavbarSearchStyled>
            </GridFilterContent>
            <Divider />
            <DialogContentStyled>
              <SharedMobileSuggestion
                page={props.page}
                triggerGetCompanySuggestions={triggerGetCompanySuggestions}
                setSearch={setSearch}
                onTriggerDiscoverJob={onTriggerDiscoverJob}
              />
            </DialogContentStyled>
            <DialogActionsStyled>
              <Fragment>
                <Button
                  button_type={"OutlinedIndigo"}
                  desktop_width={"Medium"}
                  text_transform={"capitalize"}
                  margin={"0 10px 0"}
                  cancel_button="true"
                  onClick={handleCancel}
                  margin_right="true"
                >
                  cancel
                </Button>
                <Button
                  button_type={"SolidPurple"}
                  desktop_width={"Medium"}
                  text_transform={"capitalize"}
                  onClick={onDiscoverButtonClicked}
                >
                  discover
                </Button>
              </Fragment>
            </DialogActionsStyled>
          </Fragment>
        )}
      </DialogStyled>
    </GridStyled>
  );
}

const mapStateToProps = (state) => {
  return {
    jobListFilterLanding: state.jobs.jobListFilterLanding,
    jobListFilter: state.jobs.jobListFilter,
    companyFilter: state.companies.companyFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateShowMobileFilter: bindActionCreators(
      updateShowMobileFilter,
      dispatch
    ),
    updateJobListFilterLanding: bindActionCreators(
      updateJobListFilterLanding,
      dispatch
    ),
    clearDiscoverMoreCompanyList: bindActionCreators(
      clearDiscoverMoreCompanyList,
      dispatch
    ),
    clearCompanyList: bindActionCreators(clearCompanyList, dispatch),
    clearJobList: bindActionCreators(clearJobList, dispatch),
    getJobs: bindActionCreators(getJobs, dispatch),
    updateCompanyFilter: bindActionCreators(updateCompanyFilter, dispatch),
    updateJobListFilter: bindActionCreators(updateJobListFilter, dispatch),
    clearJobSuggestion: bindActionCreators(clearJobSuggestion, dispatch),
    getCompanySuggestions: bindActionCreators(getCompanySuggestions, dispatch),
    clearCompanySuggestion: bindActionCreators(
      clearCompanySuggestion,
      dispatch
    ),
    updateJobAlertToggle: bindActionCreators(updateJobAlertToggle, dispatch),
    getTopEightCompanies: bindActionCreators(getTopEightCompanies, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharedMobileFilterDialog);
